<template>
  <div class="container">
    <div class="p_f tab_top d_f ali_c j_c">
      <div class="tab_out d_f ali_c">
        <div class="tab" :class="acTab == 0 ? 'ac' : ''" @click="acTab = 0">智慧游乐山</div>
        <div class="tab" :class="acTab == 1 ? 'ac' : ''" @click="acTab = 1">身边峨眉山</div>
      </div>
    </div>
    <div v-show="acTab == 0" class="h_content p_r">

      <!--智慧游乐山-->
      <div class="grey_cut zhyls_out">
        <div class="title d_f ali_b">
          <div class="flex_1 t_l">智慧游乐山</div>
          <div class="d_f ali_c cur_p" @click="$router.push({path:'/live',query:{tab:2}})">
            <span>查看更多</span>
            <img src="../assets/img/see_more.png" alt="">
          </div>
        </div>
        <div class="zo_out">
          <div class="d_i" v-for="(item, index) in vrArr" :key="index" @click="banner1Click(item,'out',null,'zh')">
            <van-image
              class="full"
              fit="cover"
              :src="item.cover"
            />
            <div class="zo_title t_l">{{item.name}}</div>
            <div class="d_f ali_c">
              <img src="../assets/img/h_360.png" alt="">
              <span>360°全景</span>
            </div>
          </div>
        </div>
      </div>
      <!--5g看乐山-->
      <div class="grey_cut g5">
        <div class="title d_f ali_b">
          <div class="flex_1 t_l">5G看乐山</div>
          <div class="d_f ali_c cur_p" @click="$router.push({path:'/live',query:{tab:1}})">
            <span>查看更多</span>
            <img src="../assets/img/see_more.png" alt="">
          </div>
        </div>
        <!--<div class="g5_play p_r cur_p" @click="$router.push({name:'/live5g',params:{id:g5Arr[0].videoId}})">-->
        <div class="g5_play p_r cur_p" @click="$router.push('/live5g/' + g5Arr[0].videoId)">
          <van-image
            class="full"
            fit="cover"
            :src="g5Arr[0].cover"
          />
          <div class="p_a d_f ali_c j_c">
            <img src="../assets/img/h_play.png" alt="" class="play">
            <div class="name p_a">{{g5Arr[0].name}}</div>
            <div class="p_a icon t_a">
              <img src="../assets/img/h_5g.png" alt="">
              <span>5G直播</span>
            </div>
          </div>
        </div>

      </div>
      <!--景区客流-->
      <div class="grey_cut circle">
        <div class="title d_f ali_b">
          <div class="flex_1 t_l">景区客流</div>
          <div class="d_f ali_c cur_p" @click="$router.push('/tourist')">
            <span>查看更多</span>
            <img src="../assets/img/see_more.png" alt="">
          </div>
        </div>
        <div class="c_out t_l">
          <div class="d_i" v-for="(item, index) in numArr" :key="index" @click="$router.push('/'+item.interlinkage)">
            <div class="cir_title d_f ali_c j_s">
              <div class="ct_name">{{item.name}}</div>
              <div class="ct_tag">{{item.level}}</div>
            </div>
            <div class="cir_c_out d_f">
              <div class="c_chart" v-if="alive"></div>
              <div class="cir_text">
                <div>实时客流 <span>{{item.inNum}}</span></div>
                <div>最大承载 <span class="orange">{{item.maxNum}}</span></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--旅游快报-->
      <div class="grey_cut news">
        <div class="title d_f ali_b">
          <div class="flex_1 t_l">旅游快报</div>
          <div class="d_f ali_c cur_p" @click="$router.push('/travelExpress')">
            <span>查看更多</span>
            <img src="../assets/img/see_more.png" alt="">
          </div>
        </div>
        <div class="d_f ali_c news_out cur_p" v-for="(item, index) in listTravel" :key="index" @click="$router.push({path:'travelExpressDetail',query:{id:item.contentId}})">
          <van-image
            class="full"
            fit="cover"
            :src="item.cover"
          />
          <div class="news_text_out t_l cur_p">
            <div class="news_title">{{item.title}}</div>
            <div class="text_over">{{item.synopsis}}</div>
          </div>
        </div>
      </div>
      <!--旅游路线-->
      <div class="grey_cut lines">
        <div class="title d_f ali_b">
          <div class="flex_1 t_l">旅游路线</div>
          <div class="d_f ali_c cur_p" @click="$router.push('/touristRoutes')">
            <span>查看更多</span>
            <img src="../assets/img/see_more.png" alt="">
          </div>
        </div>
        <div class="lines_out t_l">
          <div class="d_i" v-for="(item, index) in lineArr" :key="index">
            <div class="d_f">
              <div class="img_out">
                <van-image
                  class="full"
                  fit="cover"
                  :src="item.cover"
                />
              </div>
              <div class="p_r">
                <div class="line_name">{{item.title}}</div>
                <div class="line_content">{{item.synopsis}}</div>
                <div class="line_btn cur_p p_a" @click="$router.push({path:'/lineTwoDetails',query:{groupId:item.groupId}})">开始阅读</div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <!--发现乐山-->
      <div class="grey_cut leshan">
        <div class="title d_f ali_b">
          <div class="flex_1 t_l">发现乐山</div>
          <div class="d_f ali_c cur_p" @click="$router.push({path:'/live',query:{tab:3}})">
            <span>查看更多</span>
            <img src="../assets/img/see_more.png" alt="">
          </div>
        </div>
        <div class="leshan_out">
          <div class="swiper-wrapper">
            <div class="swiper-slide cur_p"
                 v-for="(item, index) in discoveryArr"
                 @click="$router.push('/liveDetail/' + item.videoId)"
                 :key="index">
              <van-image
                fit="cover"
                :src="item.cover"
              />
              <div class="le_out">
                <div class="d_f ali_c">
                  <span class="le_name">{{item.name}}</span>
                  <img class="le_v_icon" src="../assets/img/h_v.png" alt="">
                  <span>视频</span>
                </div>
                <div class="le_c t_l text_over">{{item.remark}}</div>
                <div class="le_tag t_l">
                  <div class="d_i text_over" v-for="(i,dx) in item.tagNames" :key="dx">{{i}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <!--乐山推荐-->
      <div class="grey_cut ls_tui">
        <div class="title d_f ali_b">
          <div class="flex_1 t_l">乐山推荐</div>
          <div class="d_f ali_c cur_p" @click="$router.push('/tickets')">
            <span>查看更多</span>
            <img src="../assets/img/see_more.png" alt="">
          </div>
        </div>
        <div class="ls_c_out cur_p" v-for="(item, index) in recommendListArr" :key="index" @click="$router.push({path:'/tiketDetails/'+item.bizId,query:{priceCalendarCount:item.priceCalendarCount}})">
          <van-image
            class="full"
            fit="cover"
            :src="item.cover"
          />
          <div class="ls_c">
            <div class="lsc_name">{{item.title}}</div>
            <div class="lsc_c">{{item.introduce}}</div>
            <div class="lsc_tag d_f ali_c">
              <img class="lsc_ad_icon lsc_icon" src="../assets/img/h_ls_address.png" alt="">
              <span class="flex_1 text_over">{{item.address}}</span>
              <img class="lsc_zan_icon lsc_icon" src="../assets/img/h_ls_zan.png" alt="">
              <span class="zan_num">{{item.praise}}</span>
              <!--<span class="zan_num">999</span>-->
              <img class="lsc_see_icon lsc_icon" src="../assets/img/h_le_see.png" alt="">
              <span>{{item.browse}}</span>

            </div>
          </div>
        </div>

      </div>
    </div>
    <!--<iframe src="https://wx.zyou.cloud/guidewx/#/city?city=%E4%B9%90%E5%B1%B1%E5%B8%82" frameborder="0"></iframe>-->
    <iframe v-show="acTab == 1" src="https://guide.63517.net/web/index.html" frameborder="0"></iframe>
    <tabbar :num="2"></tabbar>
  </div>
</template>
<script>
  import tabbar from '../components/tabbar';
  import '../../public/swiper/swiper.min.css';
  import  Swiper from '../../public/swiper/swiper.min.js';
  import  PinYin  from '../util/pinyin/vPInYin'
  import  api  from '../api/index'
  import { Toast, Image } from 'vant'
  import axios from 'axios'
  import * as echarts from 'echarts';
  export default {
    name: 'Guide',
    components:{
      'van-image' : Image,
      tabbar
    },
    data () {
      return{
        acTab: 0,
        alive: true,
        vrArr:[],
        g5Arr:[{}],
        otherArr:[],
        discoveryArr:[],
        lineArr:[],
        recommendListArr:[],
        listTravel:[],
        numArr:[
          // {inNum:800,maxNum:2500,name:'峨眉山',level:'5A'},
          // {inNum:625,maxNum:1500,name:'乐山大佛',level:'5A'},
          // {inNum:382,maxNum:550,name:'千佛岩',level:'5A'},
        ],
        mapInfo:{},
      }
    },
    created(){
      this.getShareInfo(
        this.$global.shareTitle,
        this.$global.visitUrl,
        this.$global.shareImage,
        this.$global.shareIntro,
      );
      this.slicedQueryVideo(6,2);//智慧游乐山
      this.slicedQueryVideo(1,1);//5g看乐山
      this.slicedQueryVideo(3,4);//市外直播
      this.slicedQueryVideo(6,3);
      this.queryGroupByGroupTypeId();
      this.listTravelNewsFlash();
      this.recommendList();
      let _t = this;
      window.addEventListener("message", function (e) {
        if (e.data.type === "GuideLink") {
          _t.ticInfo = e.data.data;
          _t.toDetail(_t.ticInfo.sellerId,_t.ticInfo.PriceCalendarConut)
        }else if(e.data.type === "navigation"){
          _t.mapInfo = {...e.data.data}
          _t.goMap();
        }
      });
    },
    mounted(){
      this.scenicState();
    },
    methods: {
      // 导航
      goMap(){
        // let arr = this.$global.loactions.split(',')
        let isMap = JSON.parse(localStorage.getItem('map')) ? true : false;
        if(!isMap) return Toast('定位失败，无法导航');
        let locations = JSON.parse(localStorage.getItem('map')).position;
        let ipGo=null;
        // ipGo = `https://uri.amap.com/navigation?from=${locations.lng},${locations.lat},起点&to=${this.mapInfo.lng},${this.mapInfo.lat},${this.mapInfo.name}&callnative=1`
        ipGo = `https://www.amap.com/dir?from[name]=起点&from[lnglat]=${locations.lng},${locations.lat}&to[name]=${this.mapInfo.name}&to[lnglat]=${this.mapInfo.lng},${this.mapInfo.lat}&policy=1&type=car&callnative=1`
        // window.open(ipGo)
        this.$router.push({
          path:'/outWindow',
          query:{
            interlinkage:encodeURI(ipGo)
          }
        })
      },
      toDetail(key,priceCalendarCount) {

        /*let data = {
          'type': 'navigation',
          data: {
            lng:lng,
            lat:lat,
            name:name,
          }
        };
        window.parent.postMessage(data, '*')*/
        this.$router.push({
          path:'/tiketDetails/' + key,
          query:{
            priceCalendarCount:priceCalendarCount || 1,
          }
        });
      },
      //乐山推荐
      recommendList(){
        let send = {
          pageSize:10,
          type:1,
        };
        api.recommendList(send).then(res => {
          this.recommendListArr = JSON.parse(JSON.stringify(res.data.data))
        })
      },
      //旅游路线
      queryGroupByGroupTypeId(){
        let send = {
          // groupTypeId:19432,
          pageIndex:1,
          pageSize:3,
        };
        api.queryGroupByGroupTypeId(send).then(res => {
          this.lineArr = JSON.parse(JSON.stringify(res.data.data));
        })
      },
      //旅游快报
      listTravelNewsFlash(){
        let send = {
          pageIndex:1,
          pageSize:3,
        };
        api.listTravelNewsFlash(send).then(res => {
          this.listTravel = JSON.parse(JSON.stringify(res.data.data));
        })
      },
      //景区客流
      scenicState(){
        let _t = this;
        _t.numArr = [];
        _t.alive = false;
        axios.get('https://www.zyou.cloud/bigdataapi/bigdata/api/zwyCont/scenicState.json').then(res => {
          _t.numArr = JSON.parse(JSON.stringify(res.data.results.states));
          _t.$nextTick(() => {
            _t.alive = true;
            setTimeout(() => {
              _t.drawRose()
            }, 100)
          });
        }, err => {
          console.log("error")
        })
      },
      // 发现了山轮播
      lsOutSwiper(){
        this.$nextTick(() => {
          let leshan_out = new Swiper('.leshan_out', {
            slidesPerView: 1.3,
            spaceBetween: 10,
            centeredSlides: true,
            loop: true,
            freeMode: false,
          });
        });
      },
      //查询视频
      slicedQueryVideo(pageSize,videoType){
        // videoType:1 5g直播； 2 vr ar；3 视频集锦；4 市外直播；
        let send = {
          pageIndex:1,
          pageSize:pageSize,
          sortName:'play_number',
          sortOrder:'desc',
          videoType:videoType,
        };
        api.slicedQueryVideo(send).then(res => {
          let data = JSON.parse(JSON.stringify(res.data.data)),_t = this;
          if(videoType == 2){
            _t.vrArr = data;
          }else if(videoType == 1){
            _t.g5Arr = data;
          }else if(videoType == 4){
            _t.otherArr = data;
          }else if(videoType == 3){
            _t.discoveryArr = data;
            setTimeout(function () {
              _t.lsOutSwiper()
            },0)
          }
        })
      },
      // 点击顶部banner
      banner1Click(row,type,isOut,zh){
        // xyhwcldnblj 内部跳转的remark匹配这个
        if(row.interlinkage){
          let out = false;
          if(type) out = true;
          if(isOut){
            if(row.remark.indexOf("xyhwcldnblj") != -1){
              out = false
            }else{
              out = true
            }
          }
          if(out){
            if(!zh){
              if(row.interlinkage.indexOf("token") != -1){
                let url = window.location.href;
                // console.log(row.interlinkage+this.$global.token+'&backUrl='+window.location.href)
                window.location.href = row.interlinkage+this.$global.token+'&backUrl='+url;

              }else{
                window.location.href = row.interlinkage
              }
            }else{
              this.$router.push({
                path:'/outWindow',
                query:{
                  interlinkage:encodeURI(row.interlinkage)
                }
              })

            }
          }else{
            this.$router.push(row.interlinkage.split('#')[1])
          }
        }
      },
      // 渲染echarts
      drawRose() {
        let roseCharts = document.getElementsByClassName('c_chart');
        for(let i = 0; i < roseCharts.length; i++){
          let cadre = echarts.init(roseCharts[i]);
          let nowPerson = ((parseInt(this.numArr[i].inNum) / parseInt(this.numArr[i].maxNum)) * 100).toFixed(2) // 百分比
          let comfort = '舒适' // 当前景区状态
          if(nowPerson > 80){
            comfort = '爆满'
          }else if(nowPerson > 50){
            comfort = '拥挤'
          }
          // debugger
          cadre.clear();
          cadre.setOption(
            {
              title: {
                text: comfort,
                textStyle: {
                  color: 'rgba(0, 0, 0, 1)',
                  fontSize: 14,
                  fontWeight: '400',
                },
                subtext: nowPerson + '%',
                subtextStyle: {
                  color: 'rgba(0, 0, 0, 1)',
                  fontSize: 12,
                  fontWeight: '100',
                },
                left: 'center',
                top: '35%'
              },
              angleAxis: {
                max: 100,
                axisLine: {
                  show: false
                },
                axisTick: {
                  show: false
                },
                axisLabel: {
                  show: false
                },
                splitLine: {
                  show: false
                },
                clockwise: true, // 逆时针
              },
              radiusAxis: {
                type: 'category',
                axisLine: {
                  show: false
                },
                axisTick: {
                  show: false
                },
                axisLabel: {
                  show: false
                },
                splitLine: {
                  show: false
                }
              },
              polar: {
                center: ['50%', '50%'],
                radius: '76%, 0'
              },
              series: [{
                type: 'bar',
                data: [{
                  name: '景区客流量',
                  value: nowPerson,
                  itemStyle: {
                    normal: {
                      color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                        offset: 0,
                        color: '#98dc4f'
                      }, {
                        offset: 1,
                        color: '#12c68d'
                      }])
                    }
                  }
                }],
                coordinateSystem: 'polar',
                roundCap: true,
                barWidth: 10,
                barGap: '-100%',
                z: 1
              }, {
                type: 'bar',
                data: [{
                  value: 100,
                  itemStyle: {
                    color: 'rgba(0, 0, 0, 0.05)',
                    shadowColor: 'rgba(0, 0, 0, 0.2)',
                    shadowBlur: 5,
                    shadowOffsetY: 2
                  }
                }],
                coordinateSystem: 'polar',
                roundCap: true,
                barWidth: 10,
                barGap: '-100%'
              }]
            },true
          )
          // 爆满时的 饼图颜色
          if(nowPerson > 80){
            cadre.setOption(
              {
                series: [{
                  type: 'bar',
                  data: [{
                    name: '景区客流量',
                    value: nowPerson,
                    itemStyle: {
                      normal: {
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                          offset: 0,
                          color: '#f67f01'
                        }, {
                          offset: 1,
                          color: '#df2323'
                        }])
                      }
                    }
                  }],
                  coordinateSystem: 'polar',
                  roundCap: true,
                  barWidth: 10,
                  barGap: '-100%',
                  z: 1,
                }, {
                  type: 'bar',
                  data: [{
                    value: 100,
                    itemStyle: {
                      color: 'rgba(255, 255, 255, 0.2)',
                      shadowColor: 'rgba(0, 0, 0, 0.2)',
                      shadowBlur: 5,
                      shadowOffsetY: 2
                    }
                  }],
                  coordinateSystem: 'polar',
                  roundCap: true,
                  barWidth: 10,
                  barGap: '-100%'
                }]
              },true
            )
          }else if(nowPerson > 50){ // 拥挤时的 饼图颜色
            cadre.setOption(
              {
                series: [{
                  type: 'bar',
                  data: [{
                    name: '景区客流量',
                    value: nowPerson,
                    itemStyle: {
                      normal: {
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                          offset: 0,
                          color: '#df5c23'
                        }, {
                          offset: 1,
                          color: '#f5b10e'
                        }])
                      }
                    }
                  }],
                  coordinateSystem: 'polar',
                  roundCap: true,
                  barWidth: 10,
                  barGap: '-100%',
                  z: 1,
                }, {
                  type: 'bar',
                  data: [{
                    value: 100,
                    itemStyle: {
                      color: 'rgba(255, 255, 255, 0.2)',
                      shadowColor: 'rgba(0, 0, 0, 0.2)',
                      shadowBlur: 5,
                      shadowOffsetY: 2
                    }
                  }],
                  coordinateSystem: 'polar',
                  roundCap: true,
                  barWidth: 10,
                  barGap: '-100%'
                }]
              },true
            )
          }
        }
        this.isLoading = false;
      },
    }
  }
</script>
<style scoped lang="scss">

  .h_content{
    .ls_tui{
      .ls_c_out{
        .ls_c{
          .lsc_tag{
            .zan_num{
              width: 25px;
            }
            .lsc_icon{
              height: 12px;
              width: auto;
              margin-right: 6px;
            }
            font-size: 12px;
            font-weight: 400;
            color: #3E3E3E;
          }
          .lsc_c{
            overflow: hidden;
            display: -webkit-box;//将元素设为盒子伸缩模型显示
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;//超出3行隐藏，并显示省略号
            text-overflow:ellipsis;/* 超出部分显示省略号 */
            margin-bottom: 18px;
            font-size: 13px;
            font-weight: 400;
            color: #7E7E7E;
          }
          .lsc_name{
            font-size: 17px;
            font-weight: bold;
            color: #3E3E3E;
            margin-bottom: 14px;
          }
          padding: 19px 14px 10px;
        }
        >.van-image{
          width: 100%;
          height: 142px;
        }
        width: 349px;
        background: #FFFFFF;
        box-shadow: 2px 1px 6px 2px rgba(47, 48, 48, 0.14);
        border-radius: 8px;
        margin-bottom: 9px;
        overflow: hidden;
      }
      .title{
        margin-bottom: 30px;
      }
      padding-top: 22px!important;
    }
    .see_other{
      .soo{
        .ali_c{
          span{
            font-size: 12px;
            font-weight: 400;
            color: #0EC653;
          }
          img{
            width: 17px;
            margin-right: 9px;
          }
        }
        .soo_name{
          margin-bottom: 13px;
          font-size: 15px;
          font-weight: 800;
          color: #3E3E3E;
        }
        >.van-image{
          margin-bottom: 15px;
          width: 111px;
          height: 112px;
          border-radius: 5px;
          overflow: hidden;
        }
      }
      .so_out{
        margin-top: 22px;
      }
      padding: 22px 13px 30px!important;
    }
    .leshan{
      .leshan_out{
        margin-top: 20px;
        .swiper-slide{
          .le_out{
            .le_tag>div{
              width: 67px;
              height: 19px;
              border: 1px solid #3E3E3E;
              border-radius: 2px;
              margin-right: 9px;
              text-align: center;
              line-height: 19px;
              font-size: 12px;
              font-weight: 400;
              color: #3E3E3E;
              /*box-sizing: border-box;*/
            }
            .le_c{
              font-size: 14px;
              font-weight: 400;
              color: #3E3E3E;
              margin: 13px 0 17px 0;
            }
            .le_v_icon{
              width: 19px;
              height: 15px;
              margin-right: 5px;
            }
            .le_name{
              margin-right: 22px;
              font-size: 18px;
              font-weight: 800;
              color: #3E3E3E;
            }
            font-size: 15px;
            font-weight: 400;
            color: #3E3E3E;
            padding: 13px 18px 8px;
          }
          .van-image{
            height: 170px;
          }
          width: 290px;
          /*height: 270px;*/
          background: #FFFFFF;
          box-shadow: 3px 2px 13px 3px rgba(47, 48, 48, 0.34);
          border-radius: 5px;
        }
      }
      .title{
        padding: 0 13px;
      }
      overflow: hidden;
      padding: 20px 0 33px 0!important;
    }
    .lines{
      .lines_out{
        >.d_i{
          .line_btn{
            font-size: 12px;
            font-weight: 500;
            color: #FFFFFF;
            width: 87px;
            height: 27px;
            background: #0EC653;
            border-radius: 5px;
            text-align: center;
            line-height: 27px;
            bottom: 11px;
            left: 0;
          }
          .line_content{
            white-space: normal;
            width: 143px;
            overflow: hidden;
            display: -webkit-box;//将元素设为盒子伸缩模型显示
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;//超出3行隐藏，并显示省略号
            text-overflow:ellipsis;/* 超出部分显示省略号 */
            font-size: 11px;
            font-weight: 500;
            color: #3E3E3E;
            margin-bottom: 43px;
          }
          .line_name{
            margin-top: 14px;
            font-size: 14px;
            width: 143px;
            white-space: normal;
            font-weight: bold;
            color: #3E3E3E;
            margin-bottom: 17px;
          }
          .img_out{
            background-color: #1ddf50;
            width: 139px;
            height: 179px;
            border-radius: 5px;
            overflow: hidden;
            margin-right: 11px;
            .van-image{
              width: 100%;
            }
          }
          width: 293px;
          margin-right: 20px;
        }
        margin-top: 22px;
        overflow-x: auto;
        white-space: nowrap
      }
      .title{
        padding-right: 13px;
      }
      padding: 22px 0 25px 13px!important;
    }
    .news{
      .news_out{
        .news_text_out{
          .text_over{
            font-size: 11px;
            font-weight: 500;
            color: #8E8E8E;
          }
          .news_title{
            height: 29px;
            font-size: 12px;
            font-weight: bold;
            color: #3E3E3E;
            line-height: 17px;
            margin-bottom: 15px;
          }
          width: calc(100% - 98px);
          box-sizing: border-box;
          padding: 7px 0 11px 0;
          border-bottom: 1PX solid #C0C0C0;
        }
        .van-image{
          width: 85px;
          height: 78px;
          border-radius: 5px;
          margin-right: 12px;
        }
        margin-bottom: 10px;
      }
      .title{
        margin-bottom: 20px;
      }
      padding: 22px 13px 25px 13px!important;
    }
    .circle{
      .c_out{
        >.d_i{
          .cir_c_out{
            .c_chart{
              width: 90px;
              height: 90px;
              margin-right: 15px;
            }
            padding: 18px;
          }
          .cir_text{
            color:#333333;
            font-size: 12px;
            align-items: center;
            span{
              margin-left: 14px;
              font-size: 21px;
              font-weight: 500;
              color: #1ABCFF;
              &.orange{
                color: #F2911E;
              }
            }
            >div{
              display: flex;
              align-items: center;
              margin-top: 10px;
            }
          }
          .cir_title{
            .ct_tag{
              width: 53px;
              height: 24px;
              border: 1px solid #FFFFFF;
              border-radius: 2px;
              text-align: center;
              line-height: 24px;
            }
            width: 266px;
            height: 39px;
            background: #26B0F8;
            border-radius: 5px 5px 0px 0px;
            line-height: 29px;
            box-sizing: border-box;
            padding: 0 17px 0 12px;
            font-size: 16px;
            font-weight: 500;
            color: #FFFFFF;
          }
          margin-right: 13px;
          background: #FFFFFF;
          box-shadow: 0px 4px 7px 1px rgba(0, 0, 0, 0.19);
          border-radius: 5px;
        }
        margin-top: 26px;
        white-space: nowrap;
        overflow-x: auto;
        padding-bottom: 10px;
      }
      .title{
        padding-right: 13px;
      }
      padding: 22px 0 16px 13px!important;
    }
    .g5{
      .g5_play{
        >.p_a{
          >div.icon{
            img{
              width: 17px;
              margin-right: 4px;
              vertical-align: middle;
            }
            border-top-left-radius: 5px;
            background-color: rgba(40,191,153,0.5);
            right: 0;
            bottom: 0;
            width: 85px;
            height: 28px;
            line-height: 28px;
            font-size: 12px;
            font-weight: 500;
            color: #fff;
            vertical-align: middle;
          }
          .name{
            font-size: 17px;
            font-weight: 800;
            color: #FFFEFE;
            left: 15px;
            bottom: 15px;
          }
          >img.play{
            width: 55px;
            height: 55px;
          }
          background: rgba(0, 0, 0, 0.5);
          width: 100%;
          left: 0;
          top: 0;
          height: 100%;
        }
        height: 214px;
        border-radius: 5px;
        overflow: hidden;
        margin-top: 22px;
      }
      padding-top: 14px!important;
    }
    .zhyls_out{
      .zo_out{
        >.d_i{
          .ali_c{
            img{
              width: 16px;
              height: 12px;
              margin-right: 6px;
            }
            span{
              font-size: 12px;
              font-weight: 400;
              color: #0EC653;
            }
          }
          .zo_title{
            margin-bottom: 12px;
            font-size: 15px;
            font-weight: 800;
            color: #3E3E3E;
          }
          .van-image{
            border-radius: 3px;
            width: 100%;
            margin-bottom: 11px;
            height: 160px;
            overflow: hidden;
          }
          width: 178px;
          margin-right: 10px;
        }
        white-space: nowrap;
        overflow-x: auto;
      }
      .title{
        margin-bottom: 19px;
      }
      padding:24px 13px 13px 19px!important;
    }
    .t_c3{
      height: 104px;
      background-color: rgba(0, 122, 255, 0);
      border-radius: 3px;
      overflow: hidden;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .t_c2{
      img{
        width: 22.5%;
      }
      padding: 19px 0 22px 0;
    }
    .tc_swiper{
      .s_cards{
        img{
          width: 51px;
        }
        width: 25%;
        /*height: 50px;*/
        color: #333333;
        font-size: 12px;
        text-align: center;
      }
      overflow: hidden;
      padding: 0 15px 20px;
      height: 86px;
      background: #FFFFFF;
      box-shadow: 0px 1px 6px 0px rgba(47, 48, 48, 0.1);
      border-radius: 5px;
    }
    .grey_cut{
      .title{
        .ali_c{
          img{
            width: 6px;
          }
          span{
            font-size: 13px;
            font-weight: 500;
            color: #4E4E4E;
            margin-right: 6px;
          }
        }
        .flex_1{
          font-size: 19px;
          font-weight: 800;
          color: #3E3E3E;
        }
      }
      padding: 0 13px 24px;
      border-bottom: 8px solid #F2F2F2;
    }
    top: -10px;
    z-index: 2;
    padding-bottom: 40px;
    box-sizing: border-box;
  }
  .container{
    .tab_top{
      .tab_out{
        .tab{
          text-align: center;
          line-height: 30px;
          color: white;
          padding: 0 20px;
          &.ac{
            color: #3BD367;
            background-color: white;
          }
        }
        overflow: hidden;
        height: 30px;
        border-radius: 17px;
        border: 1px solid #ffffff;
      }
      background-color: #3BD367;
      top: 0;
      left: 0;
      width: 100%;
      height: 50px;
      z-index: 99999;
    }
    padding-bottom: 64px;
    padding-top: 50px;
    box-sizing: border-box;
    iframe{
      width: 100%;
      height: 100%;
    }
  }
</style>
